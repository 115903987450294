import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../nuernberg/facsimile";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pages={Pages['07_03']}
                            img="/images/Nuernberg/nuernberg_bild_0014.jpg"
                            alttext="Correspondenzblatt der Generalkommission der Gewerkschaften Deutschlands, 12. Juli 1919">
                            <figcaption>
                                Correspondenzblatt der Generalkommission der Gewerkschaften Deutschlands, 12. Juli 1919
                                <span className="description">
                                        In diesem Mitteilungsblatt findet sich ein ausführlicher Bericht über den Kongress in
                                Nürnberg.
                                    </span>
                                <span className="copy">Abb. Archiv der sozialen Demokratie der Friedrich-Ebert-Stiftung, Y 18</span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Nuernberg/nuernberg_bild_0015.jpg"}
                           data-glightbox="title: Betriebsrätegesetz vom 4. Februar 1920; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0015.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Betriebsrätegesetz vom 4. Februar 1920"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                Der ADGB setzte sich dafür ein, die freien Gewerkschaften stärker in den Betrieben
                                zu verankern. Ein wichtiger Schritt war das 1920 erlassene Betriebsrätegesetz, das
                                für Unternehmen mit mindestens zwanzig Beschäftigten die Gründung eines Betriebsrats
                                vorsah.
                            </p>
                            <p className="copy">Abb. Landesarchiv Baden-Württemberg, Abt. Hauptstaatsarchiv
                                Stuttgart, J
                                150 /8 Nr. 14</p>
                        </div>
                        <figcaption>
                            Betriebsrätegesetz vom 4. Februar 1920
                            <span className="description">
                                    Der ADGB setzte sich dafür ein, die freien Gewerkschaften stärker in den Betrieben
                                    zu verankern. Ein wichtiger Schritt war das 1920 erlassene Betriebsrätegesetz, das
                                    für Unternehmen mit mindestens zwanzig Beschäftigten die Gründung eines Betriebsrats
                                    vorsah.
                                </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Nuernberg/nuernberg_bild_0008.jpg"}
                               data-glightbox="description: .custom-desc2_2; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien (1861–1920), 1919"/>
                            </a>
                            <div className="glightbox-desc custom-desc2_2">
                                <h2>Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien
                                    (1861–1920), 1919</h2>
                                <Container.If env="t">
                                    <p>
                                        Carl Legien zählte ab 1890 zu den führenden Köpfen der Gewerkschaftsbewegung.
                                        Bis zu seinem Tod 1920 war er der Vorsitzende des ADGB.
                                    </p>
                                    <p className="copy">Abb. Archiv der sozialen Demokratie der
                                        Friedrich-Ebert-Stiftung, 6/FOTA038420</p>
                                </Container.If>
                                <Container.If env="w">
                                    <p>Carl Legien engagierte sich ab 1885 in der Sozialdemokratischen Partei und
                                        schloss sich der Gewerkschaftsbewegung an. Seit er 1890 zum Vorsitzenden der
                                        „Generalkommission der Gewerkschaften Deutschlands“ gewählt wurde, ist sein Name
                                        fest mit der Geschichte der freien Gewerkschaften verknüpft. Gemeinsam mit
                                        seiner Lebensgefährtin Emma Ihrer bemühte er sich auch um den internationalen
                                        Austausch mit anderen Gewerkschaften. Ab 1893 gehörte er als Abgeordneter dem
                                        Reichstag an. 1918 handelte er als Führer der Gewerkschaften mit den
                                        Arbeitergeberverbänden im sogenannten Stinnes-Legien-Abkommen die Anerkennung
                                        der Gewerkschaften als Vertretung der Arbeiterschaft aus. Nach seinem Tod 1920
                                        übernahm Theodor Leipart seine Position als Vorsitzender des ADGB.</p>
                                    <p className="copy">Abb. Archiv der sozialen Demokratie der
                                        Friedrich-Ebert-Stiftung, 6/FOTA038420</p>
                                </Container.If>
                            </div>
                            <figcaption>
                                Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien (1861–1920),
                                1919
                                <span className="description">Carl Legien zählte seit 1890 zu den führenden Köpfen der Gewerkschaftsbewegung.
                                    Bis zu seinem Tod 1920 war er der Vorsitzende des ADGB.</span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Nuernberg/nuernberg_bild_0007.jpg"}
                               data-glightbox="title: Generalstreik gegen den Kapp-Lüttwitz-Putsch, Blick auf den Münchner Bahnhofsplatz, 15. März 1920; description: .custom-desc1_7; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Generalstreik gegen den Kapp-Lüttwitz-Putsch, Blick auf den Münchner Bahnhofsplatz, 15. März 1920"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_7">
                                <p>
                                    Bei der Niederschlagung des rechten, republikfeindlichen Kapp-Lüttwitz-Putschs 1920
                                    zeigte sich der Einfluss der Gewerkschaften. Der vom Allgemeinen Deutschen
                                    Gewerkschaftsbund ausgerufene Generalstreik trug wesentlich zum Scheitern des
                                    Umsturzversuchs bei.
                                </p>
                                <p className="copy">Abb. Stadtarchiv München, DE-1992-FS-PK-ERG-09-0297</p>
                            </div>
                            <figcaption>
                                Generalstreik gegen den Kapp-Lüttwitz-Putsch, Blick auf den Münchner Bahnhofsplatz, 15.
                                März 1920
                                <span className="description">
                                     Bei der Niederschlagung des rechten, republikfeindlichen Kapp-Lüttwitz-Putschs 1920
                                    zeigte sich der Einfluss der Gewerkschaften. Der vom Allgemeinen Deutschen
                                    Gewerkschaftsbund ausgerufene Generalstreik trug wesentlich zum Scheitern des
                                    Umsturzversuchs bei.
                                </span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Nuernberg/nuernberg_bild_0006.jpg"}
                               data-glightbox="title: Streikende Frauen in der Rüstungsindustrie, 1918; description: .custom-desc1_4; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Streikende Frauen in der Rüstungsindustrie, 1918"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_4">
                                <p>
                                    Während des Ersten Weltkriegs stieg die Zahl der in den Betrieben beschäftigten
                                    Frauen
                                    deutlich an. Die Gewerkschaften bemühten sich daher verstärkt um die Arbeiterinnen
                                    und
                                    die Vertretung ihrer Interessen, besonders um eine gleiche Bezahlung. Auf dem
                                    Kongress
                                    in Nürnberg 1919 referierte Hanna Gertrud über die „Organisation der Arbeiterinnen“.
                                </p>
                                <p className="copy">Abb. Archiv der sozialen Demokratie der Friedrich-Ebert-Stiftung,
                                    6/FOTB000789</p>
                            </div>
                            <figcaption>
                                Streikende Frauen in der Rüstungsindustrie, 1918
                                <span className="description">
                                Während des Ersten Weltkriegs stieg die Zahl der in den Betrieben beschäftigten Frauen
                                deutlich an. Die Gewerkschaften bemühten sich daher verstärkt um die Arbeiterinnen und
                                die Vertretung ihrer Interessen, besonders um eine gleiche Bezahlung. Auf dem Kongress
                                in Nürnberg 1919 referierte Hanna Gertrud über die „Organisation der Arbeiterinnen“.
                            </span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="center">
                            <StaticImage src="../../../../static/images/Nuernberg/silhouette-megafon.png" width={335} layout="fixed" placeholder="none"  alt=""/>
                        </figure>
                    </div>
                </Container.Row>
            </Container.More>
        </Container.Part>
    )
}

export default TextBlock3White
