import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Die Macht der Arbeiter<br/><span>&nbsp;</span>liegt in ihrer Organisation.“</h2>
                    <div className="inner-container">
                        <p>
                            Im Juli 1919 wurde in Nürnberg der Allgemeine Deutsche Gewerkschaftsbund (ADGB) gegründet.
                            Von diesem Dachverband erhofften die freien Gewerkschaften sich stärkeren Einfluss zur
                            Vertretung der Interessen der Arbeitnehmerinnen und Arbeitnehmer. Ab 1890 waren die
                            Einzelverbände der freien Gewerkschaften in der Generalkommission der Gewerkschaften
                            Deutschlands zusammengeschlossen. In der Weimarer Republik wuchs die Rolle der
                            Gewerkschaften als gesellschaftliche Kraft bei der Ausgestaltung des Sozialstaats und der
                            Festigung der Demokratie.
                        </p>
                        <p>
                            Richtungsweisend war der Kongress in Nürnberg 1919, wo man sich im Gebäude des Industrie-
                            und Kulturvereins eingemietet hatte. Anwesend waren über 600 Delegierte von 52
                            Gewerkschaften. Sie vertraten die Interessen von 4,8 Millionen Mitgliedern. Alle beim
                            Kongress vertretenen Verbände schlossen sich dem ADGB an, darunter die Verbände der Metall-,
                            Land-, Fabrik- und Textilarbeiter und arbeiterinnen. Zu den Zielen gehörten Tarifverträge
                            über Löhne und Arbeitsbedingungen und die verbindliche Einführung des Achtstundentags.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                1920 waren über acht Millionen Menschen im ADGB organisiert. In der Weimarer Republik
                                setzten sich die freien Gewerkschaften nicht nur für die Belange der Arbeiterschaft ein,
                                sondern auch für den Schutz der parlamentarischen Demokratie, etwa als sie sich gegen
                                den Kapp-Lüttwitz-Putsch stellten. Obwohl Teile der ADGB-Führung 1933 auf eine
                                „Anpassungspolitik“ setzten, ging das NS-Regime rigoros gegen die Gewerkschaften vor.
                                1933 wurden die Gewerkschaften zerschlagen und gleichgeschaltet. Zahlreiche
                                Gewerkschaftsmitglieder waren im Widerstand aktiv. In der Nachkriegszeit organisierten
                                die Gewerkschaften sich bald neu und sind auch heute wichtige Akteure der demokratischen
                                Mitbestimmung.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
