import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5More = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="bg-img parallax-bg parallax-nuernberg" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Nuernberg/nuernberg_bild_0016.jpg"}
                               data-glightbox="title: Postkarte der Vereinigten Gewerkschaften Nürnbergs zur Maifeier 1905; description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0016.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Postkarte der Vereinigten Gewerkschaften Nürnbergs zur Maifeier 1905"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <p>
                                    Um 1890 entwickelte sich der 1. Mai weltweit zum Protest- und Feiertag der
                                    Arbeiterbewegung. Seitdem rufen an diesem Tag auch Gewerkschaften zu Kundgebungen
                                    auf.
                                    <p className="copy">Abb. Stadtarchiv Nürnberg, A 34 Nr. A34-3109</p>
                                </p>
                            </div>
                            <figcaption>
                                Postkarte der Vereinigten Gewerkschaften Nürnbergs zur Maifeier 1905
                                <span className="description">
                                    Um 1890 entwickelte sich der 1. Mai weltweit zum Protest- und Feiertag der
                                    Arbeiterbewegung. Seitdem rufen an diesem Tag auch Gewerkschaften zu Kundgebungen
                                    auf.
                            </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Nuernberg/nuernberg_bild_0013.jpg"}
                               data-glightbox="title: Großkundgebung des Deutschen Gewerkschaftsbundes, Landesbezirk Bayern in Nürnberg, 23. Oktober 1982; description: .custom-desc1_12; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0013.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Großkundgebung des Deutschen Gewerkschaftsbundes, Landesbezirk Bayern in Nürnberg, 23. Oktober 1982"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_12">
                                <p>
                                    Die Kundgebung unter dem Motto „Wehret den Anfängen – verteidigt unseren
                                    Sozialstaat“ war vom DGB-Landesvorstand nach einem starken Anstieg der
                                    Arbeitslosigkeit beschlossen worden. Mit über 80.000 Teilnehmenden war sie die
                                    größte gewerkschaftliche Kundgebung der Nachkriegszeit in Nürnberg. Demonstriert
                                    wurde unter anderem gegen den Abbau sozialer Sicherheit und für die
                                    35-Stunden-Woche.
                                    <p className="copy">Abb. picture alliance / dpa / Karl Staedele</p>
                                </p>
                            </div>
                            <figcaption>
                                Großkundgebung des Deutschen Gewerkschaftsbundes, Landesbezirk Bayern in Nürnberg, 23.
                                Oktober 1982
                                <span className="description">
                                    Die Kundgebung unter dem Motto „Wehret den Anfängen – verteidigt unseren
                                    Sozialstaat“ war vom DGB-Landesvorstand nach einem starken Anstieg der
                                    Arbeitslosigkeit beschlossen worden. Mit über 80.000 Teilnehmenden war sie die
                                    größte gewerkschaftliche Kundgebung der Nachkriegszeit in Nürnberg. Demonstriert
                                    wurde unter anderem gegen den Abbau sozialer Sicherheit und für die
                                    35-Stunden-Woche.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Nuernberg/nuernberg_bild_0012.jpg"}
                               data-glightbox="title: Gründungskongress des Deutschen Gewerkschaftsbundes in München, Oktober 1949; description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Gründungskongress des Deutschen Gewerkschaftsbundes in München, Oktober 1949"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    Nach dem Zweiten Weltkrieg wurde wieder ein gewerkschaftlicher Dachverband gegründet
                                    – der Deutsche Gewerkschaftsbund (DGB). An der Gründung in München beteiligten sich
                                    16 Branchengewerkschaften, die die rund fünf Millionen Gewerkschaftsmitglieder in
                                    Westdeutschland vertraten.
                                    <p className="copy">Abb. Archiv der sozialen Demokratie der
                                        Friedrich-Ebert-Stiftung, 6/FOTB037986</p>
                                </p>
                            </div>
                            <figcaption>
                                Gründungskongress des Deutschen Gewerkschaftsbundes in München, Oktober 1949
                                <span className="description">
                                    Nach dem Zweiten Weltkrieg wurde wieder ein gewerkschaftlicher Dachverband gegründet
                                    – der Deutsche Gewerkschaftsbund (DGB). An der Gründung in München beteiligten sich
                                    16 Branchengewerkschaften, die die rund fünf Millionen Gewerkschaftsmitglieder in
                                    Westdeutschland vertraten.
                            </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Nuernberg/nuernberg_bild_0010.jpg"}
                               data-glightbox="title: DGB-Kundgebung am Gewerkschaftshaus in Nürnberg, 1. Mai 2019; description: .custom-desc1_13; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="DGB-Kundgebung am Gewerkschaftshaus in Nürnberg, 1. Mai 2019"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_13">
                                <p>
                                    Zur Kundgebung am 1. Mai 2019 rief der DGB unter dem Motto „Europa. Jetzt aber richtig“ auf.
                                    <p className="copy">Abb. DGB Region Mittelfranken, Chandra Moennsad</p>
                                </p>
                            </div>
                            <figcaption>
                                DGB-Kundgebung am Gewerkschaftshaus in Nürnberg, 1. Mai 2019
                                <span className="description">
                                Zur Kundgebung am 1. Mai 2019 rief der DGB unter dem Motto „Europa. Jetzt aber richtig“ auf.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock5More
