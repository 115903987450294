import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Ein Ort der Demokratie für die Nürnberger Gewerkschaftsgeschichte</h2>
                    <p>
                        Der Gewerkschaftskongress 1919 fand im Saalbau des Nürnberger Industrie- und Kulturvereins
                        statt. Dieses Gebäude war jedoch kein fester Treffpunkt der Gewerkschaften, sondern wurde
                        wegen der Größe seines Festsaals für unterschiedliche Veranstaltungen vermietet. 1935
                        verabschiedeten die Nationalsozialisten im Saalbau die sogenannten Nürnberger Gesetze. Diese
                        stellten für das NS-Regime einen zentralen Schritt für den Prozess der Entrechtung und
                        Verfolgung der jüdischen Deutschen dar, an dessen Ende die Ermordung von Millionen Juden und
                        anderen Minderheiten stand. Im Zweiten Weltkrieg wurde das Gebäude beschädigt, Ende der
                        1960er-Jahre schließlich abgerissen.
                    </p>
                    <p>
                        Als Ort der Demokratie wird aus diesen Gründen nicht der Tagungsort des Kongresses 1919,
                        sondern das DGB-Haus ausgezeichnet, das ohnehin viel enger mit der Geschichte der
                        Gewerkschaften verbunden ist.
                    </p>
                    <div className="center">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery1"
                               href={"/images/Nuernberg/nuernberg_bild_0009.jpg"}
                               data-glightbox="title: Das Gebäude des Industrie- und Kulturvereins am Frauentorgraben 49 in Nürnberg, 1920; description: .custom-desc1_8; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Das Gebäude des Industrie- und Kulturvereins am Frauentorgraben 49 in Nürnberg, 1920"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_8">
                                <p className="copy">
                                    Abb. Stadtarchiv Nürnberg, A38-C-122-8
                                </p>
                            </div>
                            <figcaption>
                                Das Gebäude des Industrie- und Kulturvereins <br/> am Frauentorgraben 49 in Nürnberg, 1920
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2Beige
