import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <div>
            <Container.Fullwidth key={key.toString() + 'slider'}>
                <Container.Novel id={key} sliderTitleId="nodelTitle">
                    <div className="bg-grey">
                        <p>1919 Nürnberg – Gewerkschaftshaus</p>
                        <h2>Am Morgen des 4. Verhandlungstages beim Kongress der Gewerkschaften Deutschlands in Nürnberg im Juli 1919</h2>
                        <StaticImage src="../../../../static/images/Nuernberg/Novel/Szene_1_Bild_1.png" placeholder="none"  alt=""/>
                    </div>
                    <StaticImage src="../../../../static/images/Nuernberg/Novel/Szene_1_Bild_2.png" placeholder="none" alt=""/>
                    <StaticImage src="../../../../static/images/Nuernberg/Novel/Szene_1_Bild_3.png" placeholder="none"  alt=""/>
                    <StaticImage src="../../../../static/images/Nuernberg/Novel/Szene_1_Bild_4.png" placeholder="none"  alt=""/>
                </Container.Novel>
            </Container.Fullwidth>
        </div>
    )
}

export default Novel

