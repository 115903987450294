import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Gewerkschaften in Nürnberg</h2>
                    <p>
                        Im 19. Jahrhundert entwickelte sich Nürnberg zu einer bedeutenden Industrie- und
                        Arbeiterstadt Süddeutschlands und zu einem Zentrum der Arbeiterbewegung in Bayern. In
                        Hochzeiten arbeiteten hier bis zu 94.000 Menschen in der Metallindustrie. Nürnberger
                        Gewerkschaftsmitglieder beteiligten sich an der Gründung des Allgemeinen Deutschen
                        Gewerkschaftsbunds 1919. Nach dem Zweiten Weltkrieg nahm das gewerkschaftliche Leben in
                        Nürnberg schnell wieder Fahrt auf. Schon im September 1945 gründete sich der Allgemeine
                        Deutsche Gewerkschaftsbund in Nürnberg mit Genehmigung der amerikanischen Militärregierung
                        als eine der ersten Gewerkschaften in Bayern neu. 1949 begann mit der Gründung des Deutschen
                        Gewerkschaftsbunds eine neue Phase der Gewerkschaftsarbeit – die Einzelgewerkschaften wurden
                        zu eigenständigen Gewerkschaften unter dem Dach des DGB.
                    </p>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Nuernberg/nuernberg_bild_0011.jpg"}
                           data-glightbox="title: Gewerkschaftshaus des Deutschen Metallarbeiter-Verbands in Nürnberg, 1911; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Gewerkschaftshaus des Deutschen Metallarbeiter-Verbands in Nürnberg, 1911"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p>
                                Ab 1908 hatte der Deutsche Metallarbeiter-Verband sein Gewerkschaftshaus in der
                                Kartäusergasse 12–14. 1933 wurde es von den Nationalsozialisten besetzt. 1945
                                wurde das im Krieg zerstörte Gebäude wieder aufgebaut und diente bis zum Umzug
                                an den Kornmarkt in den 1970er-Jahren als Gewerkschaftshaus.
                                <p className="copy">Abb. Stadtarchiv Nürnberg, A 34 Nr. A34-0862</p>
                            </p>
                        </div>
                        <figcaption>
                            Gewerkschaftshaus des Deutschen Metallarbeiter-Verbands in Nürnberg, 1911
                            <span className="description">
                                        Ab 1908 hatte der Deutsche Metallarbeiter-Verband sein Gewerkschaftshaus in der
                                        Kartäusergasse 12–14. 1933 wurde es von den Nationalsozialisten besetzt. 1945
                                        wurde das im Krieg zerstörte Gebäude wieder aufgebaut und diente bis zum Umzug
                                        an den Kornmarkt in den 1970er-Jahren als Gewerkschaftshaus.
                                    </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox karte">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Nuernberg/Entstehung-Tarifvertrag.png"}
                           data-glightbox="title: Entstehung eines Tarifvertrags;">
                            <StaticImage src="../../../../static/images/Nuernberg/Entstehung-Tarifvertrag.png" width={335} layout="fixed" placeholder="none"
                                         alt="Entstehung eines Tarifvertrags"/>
                        </a>
                        <figcaption>
                            Entstehung eines Tarifvertrags
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock4Beige
