import Headerimage from "./Headerimage";
import TextBlock1Blue from "./TextBlock1Blue";
import Novel from "./Novel";
import TextBlock2Beige from "./TextBlock2Beige";
import TextBlock3White from "./TextBlock3White";
import TextBlock4Beige from "./TextBlock4Beige";
import TextBlock5More from "./TextBlock5More";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2Beige,
    TextBlock3White,
    TextBlock4Beige,
    TextBlock5More,
    Video,
];

export {Parts};
