import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Nuernberg" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1919<br/>Nürnberg<br/>Gewerkschaftshaus</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Zivilgesellschaft</li>
                                <li>Mitbestimmung</li>
                                <li>Föderalismus</li>
                                <li>Interessenvertretung</li>
                            </ul>
                            <p>
                                Die Vertreter der freien Gewerkschaften aus ganz Deutschland trafen sich 1919 in
                                Nürnberg zur Gründung eines gemeinsamen Dachverbands – des Allgemeinen Deutschen
                                Gewerkschaftsbundes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Nuernberg/nuernberg_bild_0004.jpg"}
                                   data-glightbox="title: Gewerkschaftshaus Nürnberg, Kornmarkt 5-7, 1972; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Gewerkschaftshaus Nürnberg, Kornmarkt 5-7, 1972"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Von 1908 bis in die 1970er-Jahre hatte der Deutsche Metallarbeiter-Verband sein
                                        Gewerkschaftshaus in der Kartäusergasse. 1970 ließ die Industriegewerkschaft
                                        Metall ein größeres Gebäude am Kornmarkt erbauen, das bis heute Sitz der
                                        Nürnberger Gewerkschaften und des Deutschen Gewerkschaftsbunds ist.
                                    </p>
                                    <p className="copy">
                                        Abb. Stadtarchiv Nürnberg, A 40 Nr. A40-L-1390-41
                                    </p>
                                </div>
                                <figcaption>
                                    Gewerkschaftshaus Nürnberg, Kornmarkt 5-7, 1972
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Nuernberg/nuernberg_bild_0005.jpg"}
                                   data-glightbox="title: Generalversammlung des Deutschen Metallarbeiter-Verbandes in Nürnberg, 1901; description: .custom-desc1_3; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Nuernberg/nuernberg_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Generalversammlung des Deutschen Metallarbeiter-Verbandes in Nürnberg, 1901"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_3">
                                    <p>
                                        Der 1891 gegründete Deutsche Metallarbeiter-Verband war während der Weimarer
                                        Republik die größte Einzelgewerkschaft des Allgemeinen Deutschen
                                        Gewerkschaftsbunds.
                                    </p>
                                    <p className="copy">
                                        Abb. Fotoarchiv der IG Metall
                                    </p>
                                </div>
                                <figcaption>
                                    Generalversammlung des Deutschen Metallarbeiter-Verbandes in Nürnberg, 1901
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
