import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Correspondenzblatt der Generalkommission der Gewerkschaften Deutschlands, 12. Juli
                1919</h3>
            <h4>Vom Gewerkschaftskongress in Nürnberg.</h4>
            <p>
                Im Großen Saale des Industrie- und Kulturvereins in Nürnberg begann der Zehnte Kongress der
                Gewerkschaften Deutschlands am 30. Juni seine Verhandlungen. Legien eröffnete den Kongress mit einer
                Ansprache, in der er der Millionen Opfer des Weltkrieges mit Worten warmer Teilnahme gedachte. Die
                Kongressteilnehmer hörten diese Ausführungen stehenden Fußes an. Nach Begrüßung der erschienenen in- und
                ausländischen Gäste wünschte der Redner den Verhandlungen einen sachlichen Verlauf, getragen von dem
                Bewusstsein, für das Wohl der Arbeiterklasse zu wirken. Namens der Nürnberger Gewerkschaften und
                Arbeiterschaft begrüßte Genosse Bohl den Gewerkschaftskongress. Im Auftrage der bayerischen Regierung,
                besonders des Sozialfürsorgeamtes, sprachen Genosse Gasteiger, namens der Stadtverwaltung der Stadt
                Nürnberg Dr. Hennerich dem Kongress ihre Grüße aus. Bei der Bureauwahl des Kongresses erhob die
                Opposition, die auf dem Boden des Rätesystems stehenden Kongressteilnehmer, den Anspruch auf Vertretung
                im Bureau durch 1 Vorsitzenden, 3 Schriftführer und 7 Mitglieder der Mandatsprüfungskommission. Man
                einigte sich, der Opposition 3 von 11 Schriftführern und 6 von 15 Mitgliedern der
                Mandatsprüfungskomission zu überlassen. Die Vertreter der Landeszentralen Schwedens und Dänemarks
                teilten mit, dass sie außerstande seien, dem Kongress beizuwohnen. Vom Schweizerischen Gewerkschaftsbund
                waren die Genossen Dürr und Greulich und Genossin Hüni, von der Gewerkschaftskommission Österreichs die
                Genossen Domes, Grünwald und Huppert, von der norwegischen Landesorganisation Naroe, von dem
                niederländischen Gewerkschaftsverband Kupers, von dem Zentralverband deutscher Konsumvereine die
                Genossen Kaufmann-Hamburg und Hammerbacher-Erlangen, von der „Volksfürsorge“ Lesche-Hamburg anwesend. Es
                folgten die Ansprachen der Gäste aus Norwegen, den Niederlanden, der Schweiz und Österreich. Der
                Norwegische Genosse Naroe betonte die enge Freundschaft, die seine Landesorganisation mit den deutschen
                Gewerkschaften verbinde. Der Holländische Genosse Kupers gab der Hoffnung Ausdruck, dass die deutschen
                Gewerkschaften auch künftig in der Arbeiter-Internationale den gebührenden Platz einnehmen werden.
                Genosse Greulich erinnerte an den ersten Nürnberger Arbeitervereinstag 1868, von dem die moderne
                Gewerkschaftsbewegung ihren Anfang nahm, und appellierte an die Einigkeit der Arbeiterklasse in den
                weiteren Kämpfen für den Sozialismus, sowie vor allem auf gewerkschaftlichem Gebiete. Genosse Dürr
                berichtete über die Entwicklung der Schweizerischen Gewerkschaften und Genosse Grünwald über die
                österreichischen Gewerkschaften, wobei er die Erwartung bekundete, dass die letzteren in Bälde mit den
                deutschen Gewerkschaften eine einheitliche Kampfesfront bilden werde. Genosse Kaufmann sprach namens des
                Zentralverbandes deutscher Konsumvereine für eine fernere gemeinsame Arbeit mit den Gewerkschaften,
                Genosse Lesche überbrachte die Grüße der gewerkschaftlich genossenschaftlichen Versicherungsgesellschaft
                „Volksfürsorge“. Der Vorsitzende Leipart dankte den Gästen und besonders den ausländischen Vertretern
                und versicherte sie der unverbrüchlichen internationalen brüderlichen Gefühle der deutschen
                Gewerkschaften.
            </p>
            <p>
                Die Geschäftsordnung und Tagesordnung des Kongresses wurden angenommen. Der Opposition wurde zum
                Rechenschaftsbericht der Generalkommission ein Vertreter mit der gleichen Redezeit, wie die des
                Berichterstatters zugestanden, ebenso je ein Korreferent für die Fragen der Aufgaben der Betriebsräte
                und der Arbeitsgemeinschaft, sowie für die Fragen der Sozialisierung der Industrie und der
                Landwirtschaft.
            </p>
            <p>
                Sodann erstattete Legien den Rechenschaftsbericht der Generalkommission. Er verweist auf den gedruckt
                vorliegenden Bericht, der leider infolge des Berliner Buchdruckhilfsarbeiterstreiks nicht rechtzeitig
                fertiggedruckt werden und zum Versand kommen konnte. In wirkungsvollen Worten erbrachte der Redner den
                Nachweis, dass die sogenannte Kriegspolitik der Generalkommission und der Verbandsvorstände von Anfang
                des Krieges entschieden auf die Sicherung der Gewerkschaften und auf die Verteidigung der Interessen der
                Arbeiterklasse gerichtet war. Gewiss standen die Gewerkschaftsleitungen auf dem Boden der
                Landesverteidigung, aber das ganze Volk trat damals für die Landesverteidigung ein, und es war eine
                Pflicht der Arbeiterklasse, zu verhüten, dass das eigene Land von Krieg verwüstet wurde. Aber die
                Gewerkschaften nahmen erst Stellung zum Krieg, als für den Versuch der Parteisprengung in den
                Gewerkschaften Propaganda gemacht wurde. Von politischen Gründen sei auch später und heute die ganze
                Opposition gegen die Gewerkschaftspolitik geleitet. Das beweisen die beschämenden Maßregelungen von
                Gewerkschaftsführern wegen ihrer Nichtzugehörigkeit zur Unabhängigen Sozialdemokratie oder
                kommunistischen Partei, und die Sonderkonferenz der Opposition auf diesem Kongress, zu der von der
                „Freiheit“ vorher öffentlich eingeladen wurde. Gegen diese Zerreißung der Arbeiterbewegung wende sich
                die Generalkommission mit aller Entschiedenheit, und sie könne für sich in Anspruch nehmen, von Anfang
                bis Ende des Kriegs stets das Interesse der Arbeiterklasse gewahrt zu haben.
            </p>
            <p>
                Der Redner der Opposition, Dissmann Frankfurt a. M., erklärte, dass die Kriegspolitik der
                Generalkommission, die auch der Partei den Stempel aufgedrückt habe, bekämpft werden müsse. Diese
                Politik sei mitschuldig an dem Unglück des Kriegs. Diese Kriegspolitik, die der Redner an einer Reihe
                einzelner Sätze aus dem „Correspondenzblatt“ der Generalkommission nachzuweisen sucht, habe ihre
                Fortsetzung auch nach der Revolution gefunden. Die Opposition verlange eine andere grundsätzliche
                Politik nach der Auffassung, dass die Gewerkschaften dem revolutionären Sozialismus als Instrument
                dienen sollen. Der Redner verteidigte auch die Maßregelung von Gewerkschaftsbeamten mit der Begründung,
                dass es der freien Entschließung jeder Organisation überlassen bleiben müsse, wem sie ihr Vertrauen
                schenke. Der Generalkommission könne er kein Vertrauensvotum erteilen. Es müsse vielmehr dafür gesorgt
                werden, dass an Stelle des alten Geistes der revolutionäre Geist zurückkehre.
            </p>
            <p>
                Die Debatte über den Rechenschaftsbericht nahm den zweiten Kongresstag völlig in Anspruch. Von der
                Opposition sprachen R. Müller Berlin, Tost Berlin, Siegle Berlin, Schuhmacher Berlin, Schliestedt
                Remscheid, Lange Berlin, Simon Nürnberg und andere. Für die Generalkommission traten Breh Hannover,
                Umbreit Berlin, R. Schmidt Berlin, Brunner Berlin, Giebel Berlin, Ehrler und Thomas Frankfurt a. M.,
                Jansson Berlin, Kloth Berlin und weitere Redner ein. Das Schlusswort des Oppositionsredners brachte
                keine weitere Vertiefung der Auseinandersetzung, während Legien in seinem Schlusswort die gesamte
                Gewerkschaftsarbeit den politischen Treibereien der Opposition gegenüberstellte.
            </p>
            <p>
                In namentlicher Abstimmung wurde eine Resolution, die der Generalkommission das Vertrauen des Kongresses
                ausspricht, von 445 Delegierten mit 3307335 Stimmen gegen 179 Delegierte mit 1483779 Stimmen angenommen.
            </p>
            <p>
                Der Kongress beschloß dann gegen eine Stimme eine Kundgebung gegen den Erlass des Reichswehrministers,
                der einem Teil der Eisenbahner das Koalitionsrecht entzieht. Der Kongress lehnte jede Gemeinschaft mit
                den von unverantwortlichen Kreisen hervorgerufen wilden Eisenbahnerstreiks ab, erhob aber gleichwohl
                Einspruch gegen jede, auch nur vorübergehende Beseitigung des Streikrechts. Er richtete jedoch an die
                Eisenbahner und an die übrige Arbeiterschaft den dringenden Appell, ihre Interessen nur im Rahmen der
                gewerkschaftlichen Organisation zu vertreten und wilde Streiks zu unterlassen.
            </p>
            <p>
                Ferner nahm der Kongress einen Protest gegen die Aufrechterhaltung des Belagerungszustandes an. In einer
                weiteren Entschließung sprach sich der Kongress angesichts der Spaltung der Sozialdemokratischen Partei,
                die auch die Einheit der Gewerkschaften gefährdet, für die Neutralität der Gewerkschaften aus, erklärte
                aber, dass die Gewerkschaften nach wie vor der Brennpunkt der Klassenbestrebungen des Proletariats seien
                und den Kampf für den Sozialismus zum Siege verhelfen müssen.
            </p>
            <p>
                Gegenüber den neuen wilden Eisenbahnerstreiks in Berlin gab der Leiter des Eisenbahnerverbandes die
                Erklärung ab, dass der Verband diesen wilden Streiks gänzlich fernsteht.
            </p>
            <p>
                Über die Organisation der Arbeiterinnen referierte Gertrud Hanna Berlin. Sie hob das starke Anwachsen
                der Frauenarbeit während des Krieges hervor und behandelte die sich daraus ergebenden Notwendigkeiten
                für die gewerkschaftliche Organisierung der Arbeiterinnen und für die Berücksichtigung der
                wirtschaftlichen Interessen der Arbeiterinnen. Sie trat für die Heranziehung organisierter Frauen zur
                tätigen Mitarbeit in den Gewerkschaften und für eine dem Wert ihrer Leistungen entsprechende Bezahlung
                der Frauenarbeit ein. Eine Resolution der Referentin sowie zwei ergänzende Anträge wurden angenommen.
            </p>
            <p>
                Die Richtlinien über die künftige Wirksamkeit der Gewerkschaften vertrat Leipart in einem überaus
                wirkungsvollen Referat. Er wies nach, dass die Gewerkschaften sich durchaus nicht bei der Revolution
                haben ausschalten lassen. Das bezeugen die großen wirtschaftlichen Errungenschaften, die ihnen von den
                gesamten Unternehmerverbänden zugestanden werden mussten. Der Redner erläuterte die von der
                Vorständekonferenz dem Kongress unterbreitenden Richtlinien, insbesondere deren Bestimmungen über
                Betriebs- und Arbeiterräte und deren Aufgaben. Die Gewerkschaften stehen auf dem Boden des Sozialismus
                und wollen die Sozialisierung durchführen helfen. Die Gewerkschaften sind auch bei der Sozialisierung
                nicht zu entbehren. Ihre Kollektivverträge, ihre Kämpfe für bessere Arbeitsbedingungen sind die besten
                Vorarbeiten dafür. Aber noch weitere Riesenkämpfe stehen uns bevor. Die Arbeiterräte bedürfen zur
                Erfüllung ihrer Aufgaben der Hilfe der Gewerkschaften. Dazu müssen die Gewerkschaften gestärkt werden.
                Die Durchsetzung unserer Ziele muss die gemeinsame Aufgabe der Betriebsräte und der Gewerkschaften sein.
            </p>
            <p>
                Der Korreferent, Rich. Müller Berlin, propagierte gegenüber den Richtlinien der Generalkommission die
                Einführung des Rätesystems. Es gelte, den Sozialismus zu verwirklichen. Das Rätesystem sei dazu
                notwendig. Es sei allerdings nicht sofort in voller Form durchführbar, sondern es müsse ein
                Übergangsstadium geben. Die Betriebs- und Arbeiterräte der Richtlinien genügten nicht. Im weiteren
                beschäftigte sich der Redner mit den Arbeitsgemeinschaften, die er als Schutzwehr der Kapitalisten
                bekämpfte. Er verlangte von der Generalkommission eine Darlegung ihres Wirtschaftsprogramms und
                unterstellte ihr die Übereinstimmung mit dem des Reichswirtschaftsamtes und mit dem von letzteren
                beabsichtigten Gesetz für einen Arbeitsfrieden. Der Redner unterbreitete dem Kongress eigene Richtlinien
                über das Rätesystem.
            </p>
            <p>
                Es folgte dann sogleich das Referat Cohens über die Arbeitsgemeinschaft der industriellen und
                gewerblichen Arbeitgeber und Arbeitnehmer Deutschlands. Der Redner erklärte einleitend, dass mit der
                Arbeitsgemeinschaft die Anerkennung der vollständigen Gleichberechtigung der Arbeiter mit den
                Arbeitgebern verwirklicht werde. Er erläuterte deren Aufbau an einer großen graphischen Darstellung und
                weist nach, dass die Tätigkeit der Gewerkschaften durch die Arbeitsgemeinschaften nicht eingeschränkt
                werde. Das Rätesystem könne dieselben nicht ersetzen. Das Wirtschaftsleben lasse sich nicht durch
                Beiseiteschieben der Unternehmer weiterführen. Das Rätesystem sei keine Frage des Prinzips, sondern nur
                der Zweckmäßigkeit. Der Korreferent Müller brachte nochmals in einstündiger Rede seine Abneigung gegen
                die Arbeitsgemeinschaft zum Ausdruck und begründete eigene Vorschläge für Wirtschaftsräte, in denen
                Arbeiter, Angestellte und Intellektuelle, aber keine Unternehmer vertreten sein sollen.
            </p>
            <p>
                In der Diskussion der beiden Punkte wies Jansson Berlin nachdrücklich die Behauptung Müllers, betreffend
                den Gesetzentwurf über Arbeitsfrieden, zurück. Diese Behauptung, die von der „Freiheit“ ausging, sei
                bereits dementiert, und das „Correspondenzblatt“ der Generalkommission habe ebenfalls die Zustimmung der
                Gewerkschaften zu einem solchen Gesetz scharf abgelehnt. Der Redner zeigte weiter aus den
                Wirtschaftszuständen in Russland die zerrüttenden Wirkungen des Rätesystems. In ihren Schlussworten
                setzten sich die beiden Referenten nochmals gehörig mit den Anhängern des Rätesystems auseinander.
            </p>
            <p>
                Die Abstimmung über die Richtlinien für die künftige Wirksamkeit der Gewerkschaften ergab die Annahme
                der Vorlage der Vorständekonferenz mit großer Mehrheit. Über die Bestimmungen betr. die Betriebsräte und
                über die Resolution betr. die Arbeitsgemeinschaft wurde namentlich abgestimmt. Die ersteren Bestimmungen
                wurden von 407 Delegierten gegen 192 Delegierte, die letztere Resolution von 420 Delegierten gegen 181
                Delegierte angenommen. Weiter wurde in einer Resolution zum Ausdruck gebracht, dass der Vorentwurf des
                Reichsarbeitsamts über Betriebsräte in wichtigen Teilen nicht allen Erwartungen entspreche, und dass das
                volle Mitbestimmungsrecht der Betriebsräte in allen Arbeiterangelegenheiten zu verlangen sei. Ein Antrag
                der Verbände der Handlungsgehilfen und Bureauangestellten, betr. Zulassungsbedingungen zur paritätischen
                Arbeitsgemeinschaft, wurde dem künftigen Bundesvorstand überwiesen.
            </p>
            <p>
                Der dem Kongress vorgelegte Satzungsentwurf für den Allgemeinen deutschen Gewerkschaftsbund wurde von
                Th. Leipart eingehend begründet. Danach wird die Generalkommission in einem Bundesvorstand und die
                Konferenz der Verbandsvorstände in einen Bundesausschuss umgewandelt. Der Bundesvorstand besteht aus 15
                Mitgliedern, von denen 7 besoldet werden (der erste Vorsitzende, zwei stellvertretende Vorsitzende, der
                erste Redakteur, der Kassierer und zwei Sekretäre). Der Ausschuss wird aus je einem Vorstandsvertreter
                jeder angeschlossenen Gewerkschaft gebildet. Der Beitrag für den Bund soll 5 Pf. pro Mitglied und
                Vierteljahr betragen. Es lagen eine große Reihe von Abänderungsanträgen vor, die teils von der
                Opposition, teils von den Anhängern der Betriebsorganisation gestellt waren. Nach deren Begründung
                wurden die gesamten Anträge an eine Kommission überwiesen. Die Kommission schlug vor, die Anträge betr.
                Betriebsorganisation abzulehnen, ohne dass hierdurch die bestehenden Organisationen der Eisenbahner und
                Gemeindearbeiter berührt werden sollen. Sie empfahl ferner, dass auf je 10000 Mitglieder ein Delegierter
                zum Gewerkschaftskongress zu wählen sei. Die Anträge der Opposition seien aus sachlichen Gründen
                unannehmbar. Einige andere Anträge seien dem Bundesausschuss als Material zu überweisen. Ferner gab die
                Kommission einige Anregungen hinsichtlich der Schaffung eines Normalstatuts für die Ortsausschüsse und
                stärkerer Berücksichtigung weiblicher Mitglieder bei Delegiertenwahlen. In der Abstimmung wurde den
                Vorschlägen der Kommission entsprechend beschlossen.
            </p>
            <p>
                Für die Fragen der Sozialisierung lagen dem Kongress zwei Referate vor. Über die Sozialisierung der
                Industrie referierte Paul Umbreit Berlin. Als Korreferenten wurde seitens der Opposition Dr. Hilferding
                (U. S. P. D.) gestellt. Der Referent Umbreit erklärte es als eine historische Pflicht der
                Arbeiterklasse, die Sozialisierung durchzuführen. Die Revolution habe der Arbeiterklasse die politische
                Macht gebracht, die diese zur Einführung der Demokratie benutzte. Dabei kann es aber sein Bewenden nicht
                haben. Der Demokratie muss die Sozialisierung folgen. Der Redner erörterte die wirtschaftliche Lage
                Deutschlands nach dem Kriege, die der Sozialisierung ungeheure Schwierigkeiten entgegenstelle. Trotzdem
                sei die Sozialisierung notwendig und die Arbeiterklasse entschlossen, für deren Durchführung zu wirken,
                nicht nur für Deutschland, sondern in allen Ländern. Sodann ging der Referent auf die Fragen der
                Verwirklichung des Sozialismus ein. Er behandelte das Wesen und die verschiedenen Formen der
                Sozialisierung sowie die Übergangsmaßnahmen, insbesondere das Genossenschaftswesen. Weiter untersuchte
                der Redner die Möglichkeiten der Sozialisierung in der Rohstoff-, Halbzeug-, und Fertigwaren-Industrie,
                im Handel und Transportversicherungswesen und sonstigen Erwerbszweigen. Er behandelt ferner die
                einzelnen Stadien der Sozialisierung, die Produktionsregelung, Produktionssteigerung, Lohn- und
                Preisregelung, Marktregelung, Gewinnverteilung und schließlich die Enteignung. Am Schlusse seiner
                Ausführungen legte der Redner die Pflichten der Arbeiterklasse und die Notwendigkeit der sozialistischen
                Erziehung und der Neugestaltung des gewerblichen Bildungswesens dar. Er schloss seine Ausführungen mit
                dem Hinweis, dass der Weg zur Sozialisierung lang sei, aber das Ziel werde erreicht durch Organisation,
                Selbsterziehung und Arbeit. Erst die Demokratie, erst der Wiederaufbau der Volkswirtschaft, erst die
                Organisation der Arbeiter, erst die Selbsterziehung führe uns zum Sozialismus.
            </p>
            <p>
                Der Korreferent Dr. Hilferding-Berlin stimmte in allen wesentlichen Punkten mit dem Referenten überein.
                Seine Ausführungen ergänzten das erste Referat in wirkungsvoller Weise. Im besonderen wandte sich der
                Korreferent gegen die Maßnahmen des Reichswirtschaftsamts sowie gegen dessen Denkschrift betreffend die
                Einführung einer Planwirtschaft. Er bezeichnete diese Planwirtschaft als eine Maßnahme, die nicht der
                Vorbereitung der Sozialisierung, sondern der Befestigung der Privatwirtschaft diene. Der Redner schloss
                seinen Vortrag mit der Betonung, dass der Sozialismus keine Lohnfrage oder Messer- und Gabelfrage sei,
                sondern eine Frage der Arbeit und Erziehung.
            </p>
            <p>
                Der seitens der Anhänger der Kommunistischen Partei vorgesehene Redner Hecker Chemnitz hatte den
                Kongress bereits verlassen und der vierte Referent Georg Schmidt Berlin, der die landwirtschaftliche
                Produktion und Ansiedlung behandeln wollte, verzichtete auf das mündliche Referat.
            </p>
            <p>
                Eine Debatte über die beiden vorliegenden Referate fand nicht statt. Von den Rednern waren keine
                Resolutionen vorgelegt. Einige Anträge, die zu diesem Punkte gestellt waren, und die staatliche
                Übernahme und sozialistische Umgestaltung der Bekleidungs- und Instandsetzungsämter sowie beschleunigte
                Maßnahmen zur Bekämpfung der Heimarbeit und die Überleitung der Heimarbeitsproduktion in
                Betriebswerkstätten fordern, wurden angenommen.
            </p>
            <p>
                Sodann stimmte der Kongress der Einrichtung gewerkschaftlicher Bezirksunterrichtskurse und der
                Weiterführung der Centralkurse zu.
            </p>
            <p>
                Zur Regelung des Lehrlingswesens lagen eine Reihe von Vorschlägen vor, die auf Grund eingehender
                Erhebungen aufgestellt worden sind. Sie wurden von Sassenbach Berlin in Kürze begründet. Die von ihm
                unterbreiteten Leitsätze wurden mit einigen grundsätzlichen und praktischen Ergänzungen angenommen.
            </p>
            <p>
                Für den Punkt „Krieg und Volkskrankheiten“ waren zwei Referate vorgesehen. Infolge der Stilllegung des
                Eisenbahnverkehrs im Frankfurter Bezirk konnte der eine Referent, Dr. David Rothschild Frankfurt a. M.,
                den Kongress nicht erreichen. Infolge der vorgerückten Zeit verzichtete auch der zweite Redner, H.
                Müller Berlin, auf sein Referat über den Ausbau der Sozialversicherung. Die von der Konferenz der
                Arbeitersekretäre zu diesem Punkte angenommenen Anträge, sowie ein Antrag der gleichen Konferenz über
                Verstaatlichung und Kommunalisierung der Sekretariate und ein Antrag aus dem Kongress, betr.
                Neugestaltung der sozialen Fürsorge, wurden dem Bundesvorstand überwiesen.
            </p>
            <p>
                Die Wahlen für den neuen Vorstand des Allgemeinen deutschen Gewerkschaftsbundes hatten folgendes
                Ergebnis: Auf nachstehende gewählte Genossen entfielen 327 bis 434 Stimmen:
            </p>
            <p>
                1. Vorsitzender: C. Legien, Holzarbeiter; Stellvertreter: P. Graßmann, Buchdrucker; Stellvertreter: A.
                Cohen, Metallarbeiter; Kassierer: H. Kube, Zimmerer; 1. Redakteur: P. Umbreit, Holzarbeiter; Sekretär:
                A. Knoll, Steinsetzer; Sekretär: H. Löffler, Bergarbeiter; Beisitzer: E. Backert, Brauereiarbeiter, L.
                Brunner, Eisenbahner, C. Bruns, Fabrikarbeiter, C. Giebel, Bureauangestellte, G. Sabath, Schneider, J.
                Sassenbach, Satttler, G. Schmidt, Landarbeiter, H. Silberschmidt, Bauarbeiter.
            </p>
            <p>
                Auf eine seitens der Opposition aufgestellte Vorschlagsliste kamen nur 159 bis 168 Stimmen. Ferner
                wurden eine Reihe verschiedener Anträge zur Lebensmittelfrage, Arbeitsvermittlung und
                Erwerbslosenunterstützung, Steuerfreiheit der Gewerkschaften, gesetzliche Arbeitsruhe am 1. Mai und
                Lohnentschädigung bei Betriebsstörungen teils angenommen, teils dem Bundesvorstand überwiesen.
                Angenommen wurden ferner eine Protesterklärung gegen die Drangsalierung der organisierten Arbeiterschaft
                in den besetzten Gebieten sowie ein Antrag, kapitalistische Interessen beim Wiederaufbau der besetzten
                Gebiete auszuschließen, und eine weitere Erklärung gegen den wirtschaftlichen Boykott von Angehörigen
                der Reichswehr-Freiwilligenverbände. Der Kongress nahm ferner mit Protest Kenntnis von einem
                Brigadebefehl der Brigade v. Lettow, der auf die Niederwerfung von Streiks mit Waffengewalt gerichtet
                war. Ein Antrag der Bergarbeiter, betr. Schaffung eines Strafgesetzes gegen Arbeitgeber, die Arbeiter in
                Arbeit nehmen, die bereits in ständiger Berufsarbeit stehen, wurde dem Bundesauschuss überwiesen. Nach
                Erledigung einiger weiterer Anträge war die Tagesordnung des Kongresses erschöpft.
            </p>
            <p>
                Domes Wien dankt im Namen der ausländischen Delegierten für die gebotene Gelegenheit, an diesem
                bedeutungsvollen Kongress der deutschen Gewerkschaften teilzunehmen. Wir waren, sagt Redner, bei den
                heftigen Debatten besorgt, dass eine Spaltung eintreten könne. Aber freudigen Herzens können sie
                feststellen, dass dieses Unglück, das ein Unglück für die Arbeiterklasse der ganzen Welt gewesen wäre,
                nicht eingetreten ist. Die Einigkeit und Geschlossenheit der deutschen Gewerkschaften wird die
                Arbeiterklasse der ganzen Welt anspornen, gegen den Gewaltfrieden in Versailles vorzugehen. Die
                Österreicher fordern für sich das Recht, sich mit dem Deutschen Reich zu vereinigen, und er hege den
                Wunsch, dass einmal der deutsche Gewerkschaftskongress in der Stadt Wien tagen wird. (Stürmischer
                Beifall!)
            </p>
            <p>
                Reichel dankt den ausländischen Gästen für Ihre Teilnahme am Kongress sowie dem Lokalkomité für die
                freundliche Aufnahme in Nürnberg. Er feiert die Einheit der gewerkschaftlichen Bewegung, die erhalten
                bleiben müsse. Dieser Gedanke habe den Kongress beherrscht, in diesem Punkte besteht auf dem Kongress
                keine Meinungsverschiedenheit. Wir werden gewiss nicht volle Einigkeit in allen Fragen sobald erreichen,
                weil die grundsätzliche Auffassung vielfach sehr weit auseinander geht. Aber wir wollen versuchen, uns
                bei der Austragung der Meinungsverschiedenheiten besser zu verstehen und uns weniger verbitternder
                Formen befleißigen. Damit wäre viel erreicht. Das Reich der Freiheit beginne erst mit der Beseitigung
                der Ausbeutung und der Klassenherrschaft.
            </p>
            <p>
                Mit einem begeistert aufgenommenen Hoch auf die deutschen Gewerkschaften wurde die Tagung geschlossen.
            </p>
        </>
    )
}

export default Page;
